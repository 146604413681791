package dev.moetz.materialize

import emotion.react.css
import kotlinx.browser.document
import kotlinx.browser.window
import react.ChildrenBuilder
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.br
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.footer
import react.dom.html.ReactHTML.i
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.nav
import react.dom.html.ReactHTML.span
import react.dom.html.ReactHTML.table
import react.dom.html.ReactHTML.tbody
import react.dom.html.ReactHTML.td
import react.dom.html.ReactHTML.th
import react.dom.html.ReactHTML.thead
import react.dom.html.ReactHTML.tr
import react.dom.html.ReactHTML.ul
import web.cssom.*
import web.window.WindowTarget

object MaterializeColor {

//    enum class MaterialColorScheme {
//        Dark,
//        Light
//    }

    sealed interface ColorPalette {

        val headerNavClass: String

        val footerColorClass: String
        val footerTextClass: String

        val cardActionTextColor: String

        data object OrangeBlue : ColorPalette {
            override val headerNavClass: String = "blue darken-1"

            override val footerColorClass: String = "orange"
            override val footerTextClass: String = "orange-text text-lighten-3"

            override val cardActionTextColor: String = "orange-text"
        }

        data object GreenBlue : ColorPalette {
            override val headerNavClass: String = "blue darken-1"

            override val footerColorClass: String = "green"
            override val footerTextClass: String = "green-text text-lighten-3"

            override val cardActionTextColor: String = "green-text"
        }
    }

    var colorPalette: ColorPalette = ColorPalette.OrangeBlue
        private set

//    var colorScheme: MaterialColorScheme = getSystemColorScheme()
//        private set

    fun setColorPalette(colorPalette: ColorPalette) {
        this.colorPalette = colorPalette
    }

//    fun setColorScheme(colorScheme: MaterialColorScheme) {
//        this.colorScheme = colorScheme
//    }

//    private fun getSystemColorScheme(): MaterialColorScheme {
//        return try {
//            if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
//                MaterialColorScheme.Dark
//            } else {
//                MaterialColorScheme.Light
//            }
//        } catch (throwable: Throwable) {
//            MaterialColorScheme.Dark
//        }
//    }
}


fun <T> ChildrenBuilder.mCollapsible(
    id: String,
    items: List<T>,
    headerBlock: ChildrenBuilder.(item: T) -> Unit,
    bodyBlock: ChildrenBuilder.(item: T) -> Unit,
    headerAdditionalClass: ((item: T) -> String?)? = null,
    bodyAdditionalClass: ((item: T) -> String?)? = null,
) {
    ul {
        className = ClassName("collapsible")
        this.id = id

        items.forEach { item ->
            li {
                div {
                    className = ClassName(
                        buildString {
                            append("collapsible-header")
                            headerAdditionalClass?.invoke(item)?.let { append(" $it") }
                        }
                    )

                    headerBlock.invoke(this, item)
                }
                div {
                    className = ClassName(
                        buildString {
                            append("collapsible-body")
                            bodyAdditionalClass?.invoke(item)?.let { append(" $it") }
                        }
                    )

                    bodyBlock.invoke(this, item)
                }
            }
        }
    }
}


fun ChildrenBuilder.mA(
    href: String? = null,
    target: WindowTarget? = null,
    onClick: (() -> Unit)? = null,
    additionalClass: String? = null,
    block: ChildrenBuilder.() -> Unit
) {
    a {
        if (additionalClass != null) {
            className = ClassName(additionalClass)
        }
        if (href != null) {
            this.href = href
        }
        if (target != null) {
            this.target = target
        }
        if (onClick != null) {
            this.onClick = { onClick.invoke() }
        }
        block.invoke(this)
    }
}

fun ChildrenBuilder.mButton(
    text: String? = null,
    onClick: (() -> Unit)? = null,
    additionalClass: String? = null,
    block: (ChildrenBuilder.() -> Unit)? = null
) {
    a {
        className = ClassName(buildString {
            append("waves-effect waves-light btn")
            if (additionalClass != null) {
                append(" $additionalClass")
            }
        })
        if (onClick != null) {
            this.onClick = { onClick.invoke() }
        }
        if (text != null) {
            +text
        }
        block?.invoke(this)
    }
}


fun ChildrenBuilder.mLoadingCircle() {
    br()
    br()
    div {
        className = ClassName("preloader-wrapper big active")
        div {
            className = ClassName("spinner-layer spinner-blue-only")
            div {
                className = ClassName("circle-clipper left")
                div {
                    className = ClassName("circle")
                }
            }
            div {
                className = ClassName("gap-patch")
                div {
                    className = ClassName("circle")
                }
            }
            div {
                className = ClassName("circle-clipper right")
                div {
                    className = ClassName("circle")
                }
            }
        }
    }
    br()
    br()
}

fun ChildrenBuilder.mFooter(
    socialLink: String,
    pageName: String,
    gitUrl: String,
    isOpenSource: Boolean = true,
) {
    footer {
        className = ClassName("page-footer ${MaterializeColor.colorPalette.footerColorClass}")

        div {
            className = ClassName("footer-copyright")
            mContainer {
                mRow {
                    mCol(large = 6, small = 6) {
//                        a {
//                            className = ClassName("orange-text text-lighten-3")
//                            href = "/info"
//                            +"Info"
//                        }
//
//                        br()

//                        a {
//                            className = ClassName("orange-text text-lighten-3")
//                            href = "/cookies"
//                            +"Cookie-Policy"
//                        }

                    }

                    mCol(large = 6, small = 6, additionalClass = "right-align") {
                        mA(
                            href = socialLink,
                            target = WindowTarget._blank,
                            additionalClass = MaterializeColor.colorPalette.footerTextClass
                        ) {
                            +"made with "
                        }

                        span {
                            className = ClassName("red-text")
                            +"❤"
                        }

                        br()

                        a {
                            className = ClassName(MaterializeColor.colorPalette.footerTextClass)
                            href = "/"
                            +pageName
                        }
                        +" is "

                        a {
                            className = ClassName(MaterializeColor.colorPalette.footerTextClass)
                            href = gitUrl
                            target = WindowTarget._blank
                            if (isOpenSource) {
                                +"open sourced here"
                            } else {
                                +"close sourced here"
                            }
                        }
                    }
                }
            }
        }
    }
}


data class MaterializeTab(
    val label: String,
    val active: Boolean,
    val href: String? = null,
    val onClick: (() -> Unit)? = null,
)

data class MaterialBreadcrumb(
    val label: String,
    val link: String,
)

fun ChildrenBuilder.mHeader(
    title: String?,
    tabs: List<MaterializeTab> = emptyList(),
    breadcrumbs: List<MaterialBreadcrumb> = emptyList(),
    fixed: Boolean = false,
    connected: Boolean = true,
) {
    val content: ChildrenBuilder.() -> Unit = {
        nav {
            className = ClassName("${MaterializeColor.colorPalette.headerNavClass} nav-extended")

            div {
                className = ClassName("container")

                if (title != null) {
                    div {
                        className = ClassName("nav-wrapper")
                        a {
                            id = "logo-container"
                            className = ClassName("brand-logo")
                            span {
                                css {
                                    if (connected.not()) {
                                        color = Color("red")
                                    }
                                    textWrap = TextWrap.nowrap
                                }
                                +title
                            }
                        }
                    }
                }

                if (breadcrumbs.isNotEmpty()) {
                    div {
                        className = ClassName("nav-wrapper")
                        mCol(small = 12) {
                            breadcrumbs.forEach { breadcrumb ->
                                a {
                                    className = ClassName("breadcrumb")
                                    href = breadcrumb.link
                                    +breadcrumb.label
                                }
                            }
                        }
                    }
                }

                if (tabs.isNotEmpty()) {
                    div {
                        className = ClassName("nav-content")

                        ul {
                            className = ClassName("tabs tabs-transparent")

                            tabs.forEach { tab ->
                                li {
                                    className = ClassName("tab")

                                    a {
                                        if (tab.active) {
                                            className = ClassName("active")
                                        }
                                        href = tab.href
                                        +tab.label
                                        if (tab.onClick != null) {
                                            onClick = {
                                                tab.onClick.invoke()
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    if (fixed) {
        div {
            className = ClassName("navbar-fixed")
            content.invoke(this)
        }
    } else {
        content.invoke(this)
    }
}


data class MaterializeTableColumn(
    val title: String,
    val sortable: Boolean,
)

enum class MaterialSortOrder {
    Ascending,
    Descending,
}

fun <T> ChildrenBuilder.mSortableTable(
    headerItems: List<MaterializeTableColumn>,
    items: List<T>,
    onSortColumn: (columnIndex: Int, order: MaterialSortOrder?) -> Unit,
    cellItemBuilder: ChildrenBuilder.(item: T, columnIndex: Int) -> Unit,
    sortedByColumnIndex: Int? = null,
    sortOrder: MaterialSortOrder? = MaterialSortOrder.Ascending,
) {
    table {
        thead {
            tr {
                headerItems.forEachIndexed { columnIndex, column ->
                    th {
                        if (column.sortable) {
                            a {
                                css {
                                    cursor = Cursor.pointer
                                }
                                if (columnIndex == sortedByColumnIndex) {
                                    span {
                                        +column.title
                                        i {
                                            css(ClassName("material-icons")) {
                                                verticalAlign = VerticalAlign.bottom
                                            }
                                            +when (sortOrder) {
                                                MaterialSortOrder.Ascending -> "arrow_drop_up"
                                                MaterialSortOrder.Descending -> "arrow_drop_down"
                                                null -> ""  // should not happen
                                            }
                                        }
                                    }
                                } else {
                                    +column.title
                                }
                                onClick = {
                                    when (sortOrder) {
                                        MaterialSortOrder.Ascending -> {
                                            onSortColumn.invoke(
                                                columnIndex,
                                                MaterialSortOrder.Descending
                                            )
                                        }

                                        MaterialSortOrder.Descending -> {
                                            onSortColumn.invoke(columnIndex, null)
                                        }

                                        else -> {
                                            onSortColumn.invoke(columnIndex, MaterialSortOrder.Ascending)
                                        }
                                    }
                                }
                            }
                        } else {
                            +column.title
                        }
                    }
                }
            }
        }
        tbody {
            items.forEach { item ->
                tr {
                    headerItems.forEachIndexed { index, _ ->
                        td {
                            cellItemBuilder.invoke(this, item, index)
                        }
                    }
                }
            }
        }
    }
}


fun mCollapsibleInit(vararg collapsibleIds: String) {
    val elements = collapsibleIds.map { document.getElementById(it) }
    window.asDynamic().M.Collapsible.init(elements)
}

fun ChildrenBuilder.mDivider() {
    div {
        className = ClassName("divider")
    }
}


data class MaterialCollectionItem(
    val label: String,
    val href: String? = null,
    val onClick: (() -> Unit)? = null,
    val active: Boolean = false,
    val disabled: Boolean = false,
)

fun ChildrenBuilder.mCollection(
    items: List<MaterialCollectionItem>,
) {
    div {
        className = ClassName("collection")
        items.forEach { item ->
            a {
                css(ClassName(if (item.active) "collection-item active" else "collection-item")) {
                    if (item.href == null && item.onClick != null) {
                        cursor = Cursor.pointer
                    }
                    if (item.disabled) {
                        backgroundColor = Color("#292929")
                    }
                }
                if (item.href != null) {
                    href = item.href
                }
                if (item.onClick != null) {
                    onClick = { item.onClick.invoke() }
                }
                +item.label
            }
        }
    }
}


data class MaterialCardAction(
    val label: String,
    val href: String? = null,
    val onClick: (() -> Unit)? = null,
    val color: String = "",
    val disabled: Boolean = false,
)

fun ChildrenBuilder.mCard(
    title: String,
    cardColor: String = "blue-grey darken-1",
    contentColor: String = "white-text",
    content: ChildrenBuilder.() -> Unit,
    actions: List<MaterialCardAction>,
) {
    div {
        className = ClassName("card $cardColor")

        div {
            className = ClassName("card-content $contentColor")
            span {
                className = ClassName("card-title")
                +title
            }
            content.invoke(this)
        }
        if (actions.isNotEmpty()) {
            div {
                className = ClassName("card-action")

                actions.forEach { action ->
                    a {
                        css(ClassName(action.color.takeIf { it.isNotBlank() }
                            ?: MaterializeColor.colorPalette.cardActionTextColor)) {
                            if (action.onClick != null) {
                                cursor = Cursor.pointer
                            }
                            if (action.disabled) {
                                color = Color("gray")
                            }
                        }
                        if (action.href != null) {
                            href = action.href
                        }
                        if (action.onClick != null) {
                            onClick = {
                                action.onClick.invoke()
                            }
                        }
                        +action.label
                    }
                }
            }
        }
    }
}
