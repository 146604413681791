package dev.moetz.twitcharchivemanager.page

import dev.moetz.materialize.*
import dev.moetz.reconnectingwebsocket.ReconnectingWebSocketClient
import dev.moetz.twitcharchivemanager.design.mFooter
import dev.moetz.twitcharchivemanager.model.ArchiveItem
import dev.moetz.twitcharchivemanager.model.Channel
import dev.moetz.twitcharchivemanager.model.StorageInfo
import dev.moetz.twitcharchivemanager.util.ClientServiceManager
import dev.moetz.twitcharchivemanager.util.formatHumanReadable
import dev.moetz.twitcharchivemanager.util.useStateFlow
import emotion.react.css
import kotlinx.browser.window
import kotlinx.coroutines.flow.*
import kotlinx.coroutines.launch
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.builtins.ListSerializer
import react.ChildrenBuilder
import react.FC
import react.Props
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.br
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h3
import react.dom.html.ReactHTML.i
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span
import react.dom.html.ReactHTML.table
import react.dom.html.ReactHTML.td
import react.dom.html.ReactHTML.tr
import react.useState
import web.cssom.ClassName
import web.cssom.em
import web.html.Loading
import web.window.WindowTarget
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.seconds

external interface ArchiveItemsPageServiceProps : Props {
    var manager: ArchiveItemsPageServiceManager
}

class ArchiveItemsPageServiceManager(
    val channel: Channel,
) : ClientServiceManager<List<ArchiveItem>>(
    websocketPath = "/data/ws/archive/channel/${channel.channelName}",
    initialState = emptyList(),
    stateSerializer = ListSerializer(ArchiveItem.serializer()),
    localStorageConfig = null
) {

    private val storageInfoWebSocket = ReconnectingWebSocketClient(
        url = getFullWebsocketUrlForPath("/data/ws/archive/storageInfo")
    )

    private val mutableStorageInfoFlow: MutableStateFlow<StorageInfo?> = MutableStateFlow(null)
    val storageInfoFlow: StateFlow<StorageInfo?> = mutableStorageInfoFlow.asStateFlow()

    init {
        storageInfoWebSocket.received
            .map { json.decodeFromString(StorageInfo.serializer(), it) }
            .onEach { storageInfo -> mutableStorageInfoFlow.value = storageInfo }
            .launchIn(scope)
        scope.launch {
            storageInfoWebSocket.connect()
        }
    }

}

private enum class ArchiveListTab {
    Grid,
    Table,
    Info,
}


val ArchiveItemsPageService = FC<ArchiveItemsPageServiceProps> { props ->
    val archiveItems = useStateFlow(props.manager.stateAsStateFlow)
    val storageInfo = useStateFlow(props.manager.storageInfoFlow)
    val channel = props.manager.channel

    var currentTab by useState<ArchiveListTab>(
        ArchiveListTab.entries.firstOrNull { window.location.hash.substringAfter("#") == it.name }
            ?: ArchiveListTab.Grid
    )
    var listSort by useState<Pair<Int, MaterialSortOrder?>?>(null)

    mHeader(
        title = "${channel.channelName} - VOD Archive",
        tabs = ArchiveListTab.entries.map { tab ->
            MaterializeTab(
                label = tab.name,
                active = currentTab == tab,
                onClick = {
                    currentTab = tab
                    window.location.hash = tab.name
                }
            )
        }
    )

    mContainer {

        when (currentTab) {
            ArchiveListTab.Grid -> {
                archiveItems
                    .sortedByDescending { archiveItem -> archiveItem.createdAt }
                    .groupBy { archiveItem ->
                        archiveItem.createdAt
                            ?.toLocalDateTime(TimeZone.currentSystemDefault())
                            ?.let { "${it.year}-${it.monthNumber}" }
                    }
                    .forEach { (_, archiveItemsInThatMonth) ->
                        val yearAndMonthHumanReadable = archiveItemsInThatMonth.first()
                            .createdAt
                            ?.toLocalDateTime(TimeZone.currentSystemDefault())
                            ?.let { "${it.month.name.toLowerCase().capitalize()} ${it.year}" }
                            .orEmpty()

                        h3 { +yearAndMonthHumanReadable }

                        mRow {
                            archiveItemsInThatMonth
                                .sortedByDescending { it.createdAt }
                                .forEach { archiveItem ->
                                    archiveItemCard(channel = channel, archiveItem = archiveItem)
                                }
                        }
                    }
            }

            ArchiveListTab.Table -> {
                mRow {
                    val items = archiveItems
                        .sortedByDescending { archiveItem -> archiveItem.createdAt }
                        .let { list ->
                            when (listSort?.first) {
                                1 -> if (listSort?.second == MaterialSortOrder.Descending) {
                                    list.sortedByDescending { it.title }
                                } else {
                                    list.sortedBy { it.title }
                                }

                                2 -> if (listSort?.second == MaterialSortOrder.Descending) {
                                    list.sortedByDescending { it.createdAt }
                                } else {
                                    list.sortedBy { it.createdAt }
                                }

                                3 -> if (listSort?.second == MaterialSortOrder.Descending) {
                                    list.sortedByDescending { it.duration }
                                } else {
                                    list.sortedBy { it.duration }
                                }

                                else -> list
                            }
                        }


                    mSortableTable(
                        headerItems = listOf(
                            MaterializeTableColumn(
                                title = "Thumbnail",
                                sortable = false,
                            ),
                            MaterializeTableColumn(
                                title = "Title",
                                sortable = true,
                            ),
                            MaterializeTableColumn(
                                title = "Start",
                                sortable = true,
                            ),
                            MaterializeTableColumn(
                                title = "Length",
                                sortable = true,
                            ),
                            MaterializeTableColumn(
                                title = "Actions",
                                sortable = false,
                            )
                        ),
                        items = items,
                        onSortColumn = { columnIndex, order ->
                            listSort = columnIndex to order
                        },
                        sortedByColumnIndex = listSort?.first,
                        sortOrder = listSort?.second,
                        cellItemBuilder = { archiveItem, columnIndex ->
                            when (columnIndex) {
                                0 -> {
                                    img {
                                        css {
                                            height = 3.5.em
                                        }
                                        loading = Loading.lazy
                                        src = "/download/thumbnail/${channel.channelName}/${archiveItem.id}-720.jpeg"
                                    }
                                }

                                1 -> {
                                    +archiveItem.title
                                }

                                2 -> {
                                    +archiveItem.createdAt?.toLocalDateTime(TimeZone.currentSystemDefault())
                                        ?.formatHumanReadable()
                                }

                                3 -> {
                                    +archiveItem.duration.seconds.formatHumanReadable()
                                }

                                4 -> {
                                    a {
                                        className = ClassName("waves-effect waves-light btn blue")
                                        target = WindowTarget._blank
                                        href = "/download/vod/${channel.channelName}/${archiveItem.id}"
                                        i {
                                            className = ClassName("material-icons")
                                            +"download"
                                        }
                                        +" VOD"
                                        title =
                                            "Download VOD, ${archiveItem.sizeInfo.vodFileSize.toHumanReadableStringInMaxUnitRounded()}"
                                    }

                                    +" "

                                    if (archiveItem.sizeInfo.readableChatFileSize != null) {
                                        a {
                                            className = ClassName("waves-effect waves-light btn blue")
                                            target = WindowTarget._blank
                                            href = "/download/chat/${channel.channelName}/${archiveItem.id}/simple"
                                            i {
                                                className = ClassName("material-icons")
                                                +"download"
                                            }
                                            +" Chat (TXT)"
                                            title =
                                                "Download Chat in readable format, ${archiveItem.sizeInfo.readableChatFileSize.toHumanReadableStringInMaxUnitRounded()}"
                                        }
                                    }

                                    +" "

                                    if (archiveItem.sizeInfo.verboseChatFileSize != null) {
                                        a {
                                            className = ClassName("waves-effect waves-light btn blue")
                                            target = WindowTarget._blank
                                            href = "/download/chat/${channel.channelName}/${archiveItem.id}/verbose"
                                            i {
                                                className = ClassName("material-icons")
                                                +"download"
                                            }
                                            +" Chat (JSON)"
                                            title =
                                                "Download Chat in json format (more verbose), ${archiveItem.sizeInfo.verboseChatFileSize.toHumanReadableStringInMaxUnitRounded()}"
                                        }
                                    }
                                }
                            }
                        }
                    )
                }
            }

            ArchiveListTab.Info -> {
                mRow {
                    mCol(small = 12) {
                        if (storageInfo != null) {
                            val percentage =
                                ((storageInfo.all.free.bytes.toDouble() / storageInfo.all.total.bytes.toDouble()) * 100).roundToInt()
                            +"Available Storage: ${storageInfo.all.free.toHumanReadableStringInMaxUnitWithDecimals()} of ${storageInfo.all.total.toHumanReadableStringInMaxUnitWithDecimals()} (that is ${percentage}% of the mounted volume)"
                            br()
                            +"Total consumed by this channel: ${storageInfo.channels[channel]?.toHumanReadableStringInMaxUnitWithDecimals()}"
                        }
                    }
                }
            }
        }


    }

    mFooter()
}

private fun ChildrenBuilder.archiveItemCard(channel: Channel, archiveItem: ArchiveItem) {
    mCol(small = 12, medium = 6, large = 6) {
        div {
            className = ClassName("card large")

            div {
                className = ClassName("card-image waves-effect waves-block waves-light")

                img {
                    className = ClassName("activator")
                    loading = Loading.lazy
                    src = "/download/thumbnail/${channel.channelName}/${archiveItem.id}-720.jpeg"
                }
            }

            div {
                className = ClassName("card-content")

                span {
                    className = ClassName("card-title activator white-text")

                    val dateTimeString = archiveItem.createdAt
                        ?.toLocalDateTime(TimeZone.currentSystemDefault())
                        ?.formatHumanReadable()
                        .orEmpty()

                    +"$dateTimeString: ${archiveItem.title}"

                    i {
                        className = ClassName("material-icons right")
                        +"more_vert"
                    }
                }
            }

            div {
                className = ClassName("card-reveal")


                span {
                    className = ClassName("card-title grey-text text-darken-4")

                    +"Content:"

                    i {
                        className = ClassName("material-icons right")
                        +"close"
                    }
                }

                p {
                    className = ClassName("grey-text text-darken-4")
                    table {
                        archiveItem.chapters.forEach { chapter ->
                            tr {
                                val start = chapter.segment.position.seconds
                                val duration = chapter.segment.duration.seconds
                                val end = start + duration

                                td { +start.formatHumanReadable() }
                                td { +end.formatHumanReadable() }
                                td { +chapter.description }
                            }
                        }
                    }
                }

                p {
                    a {
                        className = ClassName("waves-effect waves-light btn blue")
                        +"Download VOD (${archiveItem.sizeInfo.vodFileSize.toHumanReadableStringInMaxUnitRounded()})"
                        target = WindowTarget._blank
                        href = "/download/vod/${channel.channelName}/${archiveItem.id}"
                    }

                    +"   "

                    if (archiveItem.sizeInfo.readableChatFileSize != null) {
                        a {
                            className = ClassName("waves-effect waves-light btn blue")
                            +"Download Chat (${archiveItem.sizeInfo.readableChatFileSize.toHumanReadableStringInMaxUnitRounded()})"
                            target = WindowTarget._blank
                            href = "/download/chat/${channel.channelName}/${archiveItem.id}/simple"
                        }
                    }

                    +"   "

                    if (archiveItem.sizeInfo.verboseChatFileSize != null) {
                        a {
                            className = ClassName("waves-effect waves-light btn blue")
                            +"Download Verbose Chat (${archiveItem.sizeInfo.verboseChatFileSize.toHumanReadableStringInMaxUnitRounded()})"
                            target = WindowTarget._blank
                            href = "/download/chat/${channel.channelName}/${archiveItem.id}/verbose"
                        }
                    }
                }

            }
        }
    }
}