package dev.moetz.twitcharchivemanager.model

import dev.moetz.twitcharchivemanager.FileSize
import dev.moetz.twitcharchivemanager.bytes
import kotlinx.serialization.Serializable

@Serializable
data class StorageInfo(
    val all: DirectorySpaceInfo,
    val channels: Map<Channel, FileSize>,
)

@Serializable
data class DirectorySpaceInfo(
    val free: FileSize,
    val total: FileSize,
) {

    val used: FileSize get() = (total.bytes - free.bytes).bytes
}

