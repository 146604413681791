package dev.moetz.twitcharchivemanager.page

import dev.moetz.materialize.*
import dev.moetz.twitcharchivemanager.design.mFooter
import dev.moetz.twitcharchivemanager.model.Channel
import dev.moetz.twitcharchivemanager.util.ClientServiceManager
import dev.moetz.twitcharchivemanager.util.useStateFlow
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.builtins.serializer
import react.FC
import react.Props
import react.dom.html.ReactHTML.h3

external interface ChannelPageServiceProps : Props {
    var manager: ChannelPageServiceManager
}

class ChannelPageServiceManager : ClientServiceManager<List<Channel>>(
    websocketPath = "/data/ws/channels",
    initialState = emptyList(),
    stateSerializer = ListSerializer(Channel.serializer()),
    localStorageConfig = LocalStorageConfig("Channels")
) {

}

val ChannelPageService = FC<ChannelPageServiceProps> { props ->
    val channels = useStateFlow(props.manager.stateAsStateFlow)

    mHeader(title = "TwitchArchiveManager")

    mContainer {

        h3 {
            +"Configured channels:"
        }

        mRow {
            mCollection(
                items = channels.map { channel ->
                    MaterialCollectionItem(
                        label = channel.channelName,
                        href = "/channel/${channel.channelName}",
                    )
                }
            )
        }

    }

    mFooter()
}