package dev.moetz.twitcharchivemanager.page

import dev.moetz.materialize.mCol
import dev.moetz.materialize.mContainer
import dev.moetz.materialize.mHeader
import dev.moetz.materialize.mRow
import dev.moetz.reconnectingwebsocket.ReconnectingWebSocketClient
import dev.moetz.twitcharchivemanager.design.mFooter
import dev.moetz.twitcharchivemanager.model.ArchiveItem
import dev.moetz.twitcharchivemanager.model.StorageInfo
import dev.moetz.twitcharchivemanager.util.ClientServiceManager
import dev.moetz.twitcharchivemanager.util.formatHumanReadable
import dev.moetz.twitcharchivemanager.util.useStateFlow
import kotlinx.coroutines.flow.*
import kotlinx.coroutines.launch
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.builtins.ListSerializer
import react.ChildrenBuilder
import react.FC
import react.Props
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h3
import react.dom.html.ReactHTML.i
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span
import react.dom.html.ReactHTML.table
import react.dom.html.ReactHTML.td
import react.dom.html.ReactHTML.tr
import web.cssom.ClassName
import web.html.Loading
import web.window.WindowTarget
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.seconds

external interface ArchiveItemsPageServiceProps : Props {
    var manager: ArchiveItemsPageServiceManager
}

class ArchiveItemsPageServiceManager(
    val channelName: String,
) : ClientServiceManager<List<ArchiveItem>>(
    websocketPath = "/data/ws/archive/channel/$channelName",
    initialState = emptyList(),
    stateSerializer = ListSerializer(ArchiveItem.serializer()),
    localStorageConfig = null
) {

    private val storageInfoWebSocket = ReconnectingWebSocketClient(
        url = getFullWebsocketUrlForPath("/data/ws/archive/storageInfo")
    )

    private val mutableStorageInfoFlow: MutableStateFlow<StorageInfo?> = MutableStateFlow(null)
    val storageInfoFlow: StateFlow<StorageInfo?> = mutableStorageInfoFlow.asStateFlow()

    init {
        storageInfoWebSocket.received
            .map { json.decodeFromString(StorageInfo.serializer(), it) }
            .onEach { storageInfo -> mutableStorageInfoFlow.value = storageInfo }
            .launchIn(scope)
        scope.launch {
            storageInfoWebSocket.connect()
        }
    }

}


val ArchiveItemsPageService = FC<ArchiveItemsPageServiceProps> { props ->
    val archiveItems = useStateFlow(props.manager.stateAsStateFlow)
    val storageInfo = useStateFlow(props.manager.storageInfoFlow)
    val channelName = props.manager.channelName

    mHeader(title = "TwitchArchiveManager")

    mContainer {

        mRow {
            mCol(small = 12) {
                if (storageInfo != null) {
                    val percentage =
                        ((storageInfo.free.bytes.toDouble() / storageInfo.total.bytes.toDouble()) * 100).roundToInt()
                    +"Available Storage: ${storageInfo.free.toHumanReadableStringInMaxUnitWithDecimals()} of ${storageInfo.total.toHumanReadableStringInMaxUnitWithDecimals()} (that is ${percentage}% of the mounted volume)"
                }
            }
        }

        archiveItems
            .sortedByDescending { archiveItem -> archiveItem.createdAt }
            .groupBy { archiveItem ->
                archiveItem.createdAt
                    ?.toLocalDateTime(TimeZone.currentSystemDefault())
                    ?.let { "${it.year}-${it.monthNumber}" }
            }
            .forEach { (_, archiveItemsInThatMonth) ->
                val yearAndMonthHumanReadable = archiveItemsInThatMonth.first()
                    .createdAt
                    ?.toLocalDateTime(TimeZone.currentSystemDefault())
                    ?.let { "${it.month.name.toLowerCase().capitalize()} ${it.year}" }
                    .orEmpty()

                h3 { +yearAndMonthHumanReadable }

                mRow {
                    archiveItemsInThatMonth
                        .sortedByDescending { it.createdAt }
                        .forEach { archiveItem ->
                            archiveItemCard(channelName = channelName, archiveItem = archiveItem)
                        }
                }
            }

    }

    mFooter()
}

private fun ChildrenBuilder.archiveItemCard(channelName: String, archiveItem: ArchiveItem) {
    mCol(small = 12, medium = 6, large = 6) {
        div {
            className = ClassName("card large")

            div {
                className = ClassName("card-image waves-effect waves-block waves-light")

                img {
                    className = ClassName("activator")
                    loading = Loading.lazy
                    src = "/download/thumbnail/$channelName/${archiveItem.id}-720.jpeg"
                }
            }

            div {
                className = ClassName("card-content")

                span {
                    className = ClassName("card-title activator white-text")

                    val dateTimeString = archiveItem.createdAt
                        ?.toLocalDateTime(TimeZone.currentSystemDefault())
                        ?.formatHumanReadable()
                        .orEmpty()

                    +"$dateTimeString: ${archiveItem.title}"

                    i {
                        className = ClassName("material-icons right")
                        +"more_vert"
                    }
                }
            }

            div {
                className = ClassName("card-reveal")


                span {
                    className = ClassName("card-title grey-text text-darken-4")

                    +"Content:"

                    i {
                        className = ClassName("material-icons right")
                        +"close"
                    }
                }

                p {
                    className = ClassName("grey-text text-darken-4")
                    table {
                        archiveItem.chapters.forEach { chapter ->
                            tr {
                                val start = chapter.segment.position.seconds
                                val duration = chapter.segment.duration.seconds
                                val end = start + duration

                                td { +start.formatHumanReadable() }
                                td { +end.formatHumanReadable() }
                                td { +chapter.description }
                            }
                        }
                    }
                }

                p {
                    a {
                        className = ClassName("waves-effect waves-light btn blue")
                        +"Download VOD (${archiveItem.sizeInfo.vodFileSize.toHumanReadableStringInMaxUnitRounded()})"
                        target = WindowTarget._blank
                        href = "/download/vod/${channelName}/${archiveItem.id}"
                    }

                    +"   "

                    if (archiveItem.hasChat && archiveItem.sizeInfo.readableChatFileSize != null) {
                        a {
                            className = ClassName("waves-effect waves-light btn blue")
                            +"Download Chat (${archiveItem.sizeInfo.readableChatFileSize.toHumanReadableStringInMaxUnitRounded()})"
                            target = WindowTarget._blank
                            href = "/download/chat/${channelName}/${archiveItem.id}"
                        }
                    }
                }

            }
        }
    }
}