package dev.moetz.twitcharchivemanager

import dev.moetz.twitcharchivemanager.model.Channel
import dev.moetz.twitcharchivemanager.page.*
import kotlinx.browser.window
import react.create
import react.dom.client.createRoot
import web.dom.document

fun main() {
    val container = document.createElement("div")
    document.body.appendChild(container)

    val path = window.location.pathname
    val pathSplit = path.split("/")
        .filter { it.isNotBlank() }

    val element = when {
        path.isBlank() || path == "/" -> {
            ChannelPageService.create {
                manager = ChannelPageServiceManager()
            }
        }

        pathSplit.getOrNull(0) == "channel" && pathSplit.size >= 2 -> {
            ArchiveItemsPageService.create {
                manager = ArchiveItemsPageServiceManager(
                    channel = Channel(pathSplit.getOrNull(1).orEmpty())
                )
            }
        }

        else -> {
            NotFoundPageService.create {

            }
        }
    }

    createRoot(container).render(element)
}